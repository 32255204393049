/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  font-family: 'Inter', sans-serif;
}
.thumb_down_up{
  transition: transofrm 0.9s ease-in-out;
  cursor:pointer
}
.thumb_down_up:hover {
  transform: scale(1.2);
}
body {
  background-color: #F6AD55 !important;
}
.campaignsTable th {
  padding-left:0;
}


.pricingBottomElementContainer path {
  fill:#f6ad55d6;
}